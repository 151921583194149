import { APIFilterOP } from '@jagu/rest-api-filters-client/apiFilters'

export {
  APIFilters,
  APIFilterOP,
  APIFilterDataType,
  APIFilterDataTypeAllowedOperations,
} from '@jagu/rest-api-filters-client/apiFilters'

function addFilter(
  filterKey: string,
  value?: string | number,
  currentFilter?: any
) {
  if (value === undefined) {
    return {}
  }
  const newFilter = {
    [APIFilterOP.EQUALS]: {
      [filterKey]: value,
    },
  }
  if (currentFilter === undefined) {
    return newFilter
  }

  currentFilter.push(newFilter)
}

function addNotEqualsFilter(
  filterKey: string,
  value: string | number,
  currentFilter?: any
) {
  const newFilter = {
    [APIFilterOP.NOT_EQUALS]: {
      [filterKey]: value,
    },
  }
  if (currentFilter === undefined) {
    return newFilter
  }

  return {
    [APIFilterOP.AND]: [currentFilter, newFilter],
  }
}

export { addNotEqualsFilter, addFilter }
